.AccountFinder{
    &-messages{
        @apply
            overflow-auto
            pb-5
            pt-7
            mt-4
            border-t
            border-gray-line;
        height: 60vh
    }

    &-list{
        @apply
            flex
            flex-col
            items-start
            justify-start
            gap-2;
    }

    &-message{
        @apply
            rounded-full
            text-white
            pl-2
            pr-3
            py-1;
        
        //animate the message bubbles in
        transform: scale(0);
        transform-origin: top left;
        animation: bubble 300ms linear 0s 1 normal forwards;
        
        a{
            @apply text-white underline;
        }
    }

    &-typing{
        @apply
            block
            w-5;

        //faux typing indicator
        &::before{
            content: url("#{$dist}/three-dots.svg");
            @apply
                block
                w-full
                object-fill;
        }
    }

    &-message:nth-child(even){
        @apply
            bg-blue
            self-start
            relative
            rounded-tl-none;
        
        //caret
        &::before{
            content: '';
            background-image: inline-svg('<svg width="15" height="9" viewBox="0 0 15 9" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M0.626953 8.51366C0.626953 8.51366 1.13947 0.636963 6.70539 0.636963C6.70539 6.74587 14.3755 8.5136 14.3755 8.5136L0.626953 8.51366Z" fill="#00AEC7"/> </svg>');
            @apply
                absolute
                leading-none
                -translate-y-full;
            height: 8px;
            width: 15px;
            top: 0;
            left:-1px;
        }
    }

    &-message:nth-child(odd){
        @apply
            bg-green
            self-end
            rounded-tr-none;
        transform-origin: top right;

        //hide avatar for user input messages
        img{display: none}

        //caret
        &::before{
            content: '';
            background-image: inline-svg('<svg width="15" height="9" viewBox="0 0 15 9" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M14.625 8.01415C14.625 8.01415 14.1125 0.137451 8.54656 0.137451C8.54656 6.24636 0.876503 8.01409 0.876503 8.01409L14.625 8.01415Z" fill="#78BE20"/> </svg>');
            @apply
                absolute
                leading-none
                -translate-y-full;
            height: 8px;
            width: 15px;
            top: 0;
            right:-1px;
        }
    }

    &-answers{
        @apply
            bg-gray-light
            rounded-full
            py-sm
            px-2
            gap-1
            flex;

        @screen sm {
            @apply  overflow-x-scroll;
        }
    }

    &-answer{
        @apply
            text-black
            border-black
            border
            border-opacity-20
            text-16
            font-normal
            py-1
            px-2;
        background: darken($gray-light, 10%); 

        &:hover,
        &:focus{
            box-shadow: 2px 2px 0px $gray-medium !important;
        }
    }
}

@keyframes bubble {
    from{ opacity: 0; transform:scale(0); }
    to{ opacity: 1; transform:scale(1);}
}