.Path2CreditHero{
    &-header{  
        @apply
            rounded-tr-xl
            rounded-bl-xl
            overflow-hidden
            relative;

        &::before{
            content: '';
            @apply
                absolute
                inset-0
                z-10;
            background: linear-gradient(265.94deg, rgba(0, 0, 0, 0.39) 2.94%, rgba(0, 0, 0, 0) 69.01%);
        }
        h1{
            @apply 
                absolute 
                z-10 
                bottom-7
                right-3
                w-10/16
                text-white;
        }
    }
    &-visual{
        @apply
            w-full
            block;
    }

    //responsive
    @screen lg+{
        h1{
            @apply 
                w-14/16 
                left-1/2 
                bottom-4 
                translate-y-0 
                top-auto 
                -translate-x-1/2 
                right-auto;
        }
    }

    @screen lg{
        
        &-visual{
            @apply object-cover;
            object-position:25% center;
            height: 750px;
        }

        
    }
}



.P2CSteps{
    @apply py-15;

    &::before{
        content: '';
        background-image: url("#{$dist}/path.svg");
        @apply
            absolute
            z-0
            top-0
            left-0
            right-0
            h-full
            bg-cover
            border-b
            border-gray-light;
    }

    &-item{
        counter-increment: step-number;
        @apply
            relative
            z-10
            flex
            flex-col
            justify-between
            w-6/16
            shadow-black
            mb-15
            pb-4;
        aspect-ratio: 1.05;

        &::before{
            content: counter(step-number);
            @apply
                bg-green
                text-white
                rounded-full
                w-7
                h-7
                flex
                items-center
                justify-center
                font-bold
                mb-auto;
        }

        img{
            @apply
                self-start
                mb-3;
        }
        
        --col: 6.25%;
        &:first-child{
            margin-left: var(--col);
        }
        &:nth-child(2){
            margin-left: calc(var(--col)*8);
            margin-top:-10%;
        }
        &:nth-child(3){
            margin-left: calc(var(--col)*4);
        }
        &:nth-child(4){
            margin-left: var(--col);
        }
    }


    //responsive
    @screen lg{
        &-item{
            @apply w-1/2;

            &:nth-child(2){
                margin-left: calc(var(--col)*8);
                margin-top:0%;
            }
        }

    }

    @screen md{
        &-item{
            @apply w-2/3 aspect-auto min-w-[400px];

            &::before{
                @apply mb-10
            }


            &:nth-child(n){
                margin-left: 0;
                margin-top:0%;
            }
        }

    }
    @screen sm{
        @apply py-5;

        &-item{
            @apply w-14/16 mx-auto min-w-0;

            &:nth-child(n){
                @apply mx-auto;
            }
        }
    }
}