input::placeholder{
	@apply text-gray-dark;
}
label{
	@apply
		text-black
		font-bold
    block;
}

/*************************************
* Inputs
*************************************/
select {
	@apply block 
		border-b
		border-black
		bg-gray-light
		h-auto 
		py-3 
		px-3
		pr-9
		appearance-none 
		relative;
		
	background: url($dist + '/arrow-down.svg') $gray-light no-repeat right 24px center;
}

input[type="checkbox"]{
	@apply
		appearance-none
		border-black
		border
		rounded-[2px]
		cursor-pointer;
	@include size(20px);

	&:checked{
		@apply
			bg-blue
			bg-no-repeat
			bg-center;
		background-image: inline-svg('<svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M6.00016 11.1698L1.83016 6.99984L0.410156 8.40984L6.00016 13.9998L18.0002 1.99984L16.5902 0.589844L6.00016 11.1698Z" fill="white"/> </svg> ');
		background-size: 70%;
		border-color: darken($blue-dark, 14%);
	}
}

input[type="radio"]{
	@apply
		appearance-none
		border-black
		border
		rounded-full
		cursor-pointer
		grid
		content-center
		justify-center;
	@include size(21px);

	&::before {
		content: "";
		@include size(8px);
		border-radius: 50%;
		transform: scale(0);
		transition: 120ms transform ease-in-out;
		box-shadow: inset 1em 1em white;
	}

	&:checked{
		@apply bg-blue; 
		border-color: darken($blue-dark, 14%);

		&::before {
			
			transform: scale(1);
		}
	}
}

.form-check-label{
	@apply
		flex
		items-start
		gap-1
		my-2
		font-normal
		cursor-pointer;
	
	input{
		transform: translateY(2px);
		flex-shrink: 0;
	}
}



/*************************
* Icons inside Inputs
**************************/
input.icn{
	background-repeat: no-repeat;
	background-position: $spacing-1 center;
	padding-left: $spacing-4;

	&:focus,
	&-active{
		padding-left: $spacing-4 !important;	
	}
}


.formRow{
	@apply mb-3;
}


/*************************
* Freeform stuff
**************************/

.Form textarea {
  @apply  block 
          border-b
        border-black
        bg-gray-light
          h-auto 
          p-1 
          w-full;
}

#{$all-text-inputs}{
	@apply
		w-full
		p-3
		text-black
		bg-gray-light
		border-b
		border-black
		rounded-tr-sm
		rounded-tl-sm;
}
#{$all-text-inputs-active},
#{$all-text-inputs-focus}{
  @apply
    outline-none
    border-blue;
}

// .freeform {
// 	&-row {
// 		button[type=submit] {
// 			@apply 
// 				btn
// 				mt-[96px];
// 		}
// 	}
// }

//Floating Labels
.Floating{
	@apply relative;

	&-input{
		padding-top: calc($spacing-3 + 4px);
		padding-bottom: calc($spacing-3 - 4px);
	}
	&-input:placeholder-shown{
		@apply p-3;
	}

	&-label{
		@apply
			absolute
      		left-3
			font-normal
			text-gray-dark
			pointer-events-none
			transition-all
			top-sm
			-translate-y-0
			text-14;
	}

	&-input:placeholder-shown + &-label{
		@apply
			top-1/2
			-translate-y-1/2
			text-18;
	}

	textarea.Floating-input:placeholder-shown + &-label{
		@apply top-3 translate-y-0;
	}

}
#{$all-text-inputs}{
&.is-error{
	@apply border-red;
}
}
ul.errors{
	@apply mt-1 font-bold text-red;
}



/*************************************
* Search Forms with results
*************************************/
.Search{
	&-input{
		@apply
			border
			border-black
      bg-white
			p-3
			pl-7
			rounded-full
			w-full;
		background: url("#{$dist}/icn_search--big.svg") 24px center no-repeat;
	}

	&-cancel{
		@apply
			absolute
			right-3
			top-1/2
			-translate-y-1/2
			rounded-none
			bg-transparent
			bg-center
			p-0
			flex
			items-center
			gap-1
			leading-none;
  
		&::after{
			content:  url("#{$dist}/icn_close.svg");
			@apply
				w-3
				h-3
				block;
			transform: translateY(1px)
  		}

		span{
			@apply
				text-gray-medium
				text-12
				bg-gray-light
				block
				h-3
				w-3
				pt-sm
				text-center
				border
				border-gray-line;
			line-height: $spacing-1*1.5;
			border-radius: 4px;
		}
  	}

	&-form{ @apply relative }

	&-resultsTitle {
		@apply
			pt-2
			px-2
			uppercase;
	}

    &-result {
        @apply
            block
            p-2
			border-b
			border-gray
			transition-all;

		&:last-child {
			@apply
				border-b-0;
		}

		> h5 {
			@apply 
				text-18;
		}

		p {
			@apply
				text-16;
		}

        &:focus{
            @apply
                outline-none
                bg-gray-light;
				// my-1
				// rounded
        }
        
        /* &:first-of-type{
            @apply mt-2;
        } */
    }

	&-highlighted.prose {
		@apply text-gray-dark;
		
		h1, h2, h3, h4, h5 {
			@apply 
				text-18;
		}

		p {
			&:not(:first-child) {
				@apply
					hidden;
			}
		}

		ul {
			// list-style-position: inside;
			@apply
				list-inside;
		}
	}

	&-findBranch {
		@apply
			flex 
			justify-end 
			items-center
			text-18
			text-black;

		
		&:after {
			content: url($dist + '/arrow-right.svg');
			@apply
				block
				ml-2;
		}

		/* &.Search-findBranch {
			&--white {
				@apply  text-white;

				&:after {
					content: url($dist + 'arrow-right--white.svg');
				}
			}
		} */
	}
}
::-webkit-search-cancel-button, 
::-webkit-search-decoration, 
::-webkit-search-results-button, 
::-webkit-search-results-decoration {
    display: none;
}


/**

// Custom radio styling
.Form input[type="radio"] + label {
  @apply  relative
          cursor-pointer
          pl-4;
}

.Form input[type="radio"] + label::before {
  display: block;
  content: '';
  height: 20px;
  width: 20px;
  border-radius: 100px;
  margin-right: 16px;
  border: 1px solid $blue-light;
  background: $blue-lightest;
  position: absolute;
  top: 1px;
  left: 0;
  
}

.Form input[type="radio"] {
  position: absolute;
  height: 1px; 
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
}

.Form input[type="radio"]:checked + label::before {
  background: white;
  border: 4px solid $blue;
}

.Form input[type="radio"]:focus + label::before {
  box-shadow: 0 0px 6px $blue-light;
}

// Custom checkbox styling
.Form input[type="checkbox"] + label {
  @apply  relative
          cursor-pointer
          pl-4;
}

.Form input[type="checkbox"] + label::before {
  display: block;
  content: '';
  height: 20px;
  width: 20px;
  margin-right: 16px;
  border: 1px solid $blue-light;
  background: $blue-lightest;
  position: relative;
  position: absolute;
  top: -1px;
  left: 0;
}

.Form input[type="checkbox"] {
  position: absolute;
  height: 1px; 
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
}

.Form input[type="checkbox"]:checked + label::before {
  background: $blue;
  border-color: $blue;
}

.Form input[type="checkbox"]:checked + label::after {
  content: '';
  position: absolute;
  top: 4px;
  left: 3px;
  border-left: 2px solid white;
  border-bottom: 2px solid white;
  height: 7px;
  width: 14px;
  transform: rotate(-45deg);
}

.Form input[type="checkbox"]:focus + label::before {
  box-shadow: 0 0px 6px $blue-light;
}

.Form input[type="checkbox"].FormField-error + label::before {
  border-color: #f00;
  background: rgba(255, 0, 0, 0.1);
}

.Form input.FormField-error, textarea.FormField-error  {
  border-color: #f00;
  background: rgba(255, 0, 0, 0.1);
}

select.FormField-error {
  border-color: #f00;
  background: url($dist + 'images/icon-select.svg') rgba(255, 0, 0, 0.1) no-repeat right 20px center;
}

.FormField-errors {
  @apply  text-16
          mt-1
          block;
  color: #f00;
}

.FormStatus {
  p {
    @apply  mb-0;
  }
}

**/