.LRBlocks{
    @apply w-14/16 py-10 mx-auto relative;

    &::before{
        content:'';
        @apply
            absolute
            inset-y-0
            border-l-2
            border-black
            left-1/2;
    }

    &-block{
        @apply 
            flex
            flex-wrap
            items-center
            pb-20;
    }

    &-copy{
        @apply w-1/2 pr-8;
            
    }

    &-vis{
        @apply
            flex-1
            rounded-tl-xl
            rounded-br-xl
            pr-2
            pb-2
            border-r-4
            border-b-4
            border-green;
        transform: translateX(2px);

        img{
            @apply
                aspect-[1.1]
                w-full
                object-cover;
            border-radius: calc(5.22222rem - .88888rem) 0 calc(5.22222rem - .88888rem) 0;
        }
    }


    //Reverse
    &-block:nth-child(even){
        @apply
            flex-row-reverse;
        
        .LRBlocks-vis{
            @apply
                rounded-tl-none
                rounded-br-none
                rounded-tr-xl
                rounded-bl-xl
                pr-0
                pl-2
                border-r-0
                border-l-4
                border-green;
            transform: translateX(0px);

            img{
                @apply
                    aspect-[1.1]
                    w-full
                    object-cover;
                border-radius: 0 calc(5.22222rem - .88888rem) 0 calc(5.22222rem - .88888rem);
            }
        }

        .LRBlocks-copy{
            @apply pl-8 pr-0
        }
    }


    //responsive
    @screen lg{
        @apply w-full
    }

    @screen md{
        @apply py-0;

        &::before{ content: none;}

        &-block{
            @apply mb-9 gap-4;
        }

        &-copy,
        &-vis{
            @apply w-full
        }

        &-copy{
            @apply p-0 text-center;
        }

        &-block:nth-child(even) .LRBlocks-copy{
            @apply p-0;
        }
    }

}