.Pledge{
    @apply
        border-black 
        border-2 
        rounded 
        rounded-tr-none 
        rounded-bl-none 
        flex 
        flex-col 
        items-center 
        text-center 
        p-6 
        pt-10
        gap-5;

    img{
        max-width: 80px;
    }

    @screen lg{
        @apply
            p-3
    }
}


.Values{
    --gutter: 20px;

    &-list{
        --columns: 5;
        @apply 
            grid 
            overflow-x-scroll
            py-5
            mt-5;
        cursor: grab;
        grid-gap: calc(var(--gutter));
        grid-template-columns: 2.5% repeat(var(--total), calc(100% * (var(--columns) / 16) - var(--gutter) * 2)) 2.5%;
        // grid-template-columns: 2.5% repeat(var(--total), calc(#{100*(4 / 16)}% - var(--gutter) * 2)) 2.5%;
        grid-template-rows: minmax(480px, 1fr);
        -webkit-overflow-scrolling: touch;
        scrollbar-width: none;
        -ms-overflow-style: none;
        scroll-snap-type: x proximity;
        scroll-padding-left: 2.5%;

        @screen lg+{ --columns: 6; }
        @screen md{ --columns: 10; }
        @screen md{ --columns: 15; }


        &::before,
        &::after {
            content: '';
        }

        &::-webkit-scrollbar{  @apply hidden; }

        &.is-down{
            scroll-snap-type: none;

            li{
                transform: scale(1.02);
            }
        }
    }

    &-item{
        counter-increment: belief-number;
        @apply
            bg-white
            border-2
            border-black
            rounded
            shadow-box-small
            shadow-black
            p-4
            flex
            flex-col
            justify-end
            relative;
        scroll-snap-align: start;
        padding-top: $spacing-1 * 35;
        // aspect-ratio: .6;

        &:not(li:last-child)::after{
            content: '';
            @apply 
                absolute;
            top:0;
            bottom:0;
            width:1px;
            right:var(--gutter);
        }

        &::before{
            content: 'Belief 'counter(belief-number);
            @apply
                bg-green
                text-white
                font-bold
                uppercase
                text-14
                tracking-widest
                rounded-full
                inline-block
                px-2
                py-sm
                absolute
                top-4
                left-4;
        }
    }
}
