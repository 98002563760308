.FiftyHero{
    background: url("#{$dist}/50years.svg")  no-repeat;
    background-size: contain;
    aspect-ratio: 1426/654;
    margin-bottom: 200px;

    @screen md{
        margin-bottom: 100px;
    }
}
.FiftyItem{
    @apply
        w-1/2
        mx-auto
        py-4;

    @screen lg{
        @apply w-2/3;
    }
    @screen md{
        @apply w-3/4;
    }
    @screen md{
        @apply w-full;
    }

    &::before{
        content: '';
        @apply
            block
            w-[1px]
            h-20
            border-l
            border-gray-line
            mx-auto
            mb-3;
    }

    .Tag{
        @apply
            mb-8
            mx-auto
            inline-block;
    }
}