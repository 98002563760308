.HelpSearch{
    @apply  relative z-40;

    &-input {
            @apply
                w-full
                relative
                rounded-full
                border
                border-black
                p-3
                pl-8
                text-24
                leading-none
                transition-all
                duration-200;
        background: white url("#{$dist}/icn_search--big.svg") 24px center no-repeat;

        &:focus{
            box-shadow: 0px 0px 12px rgba(0, 171, 200, 0.25);
            
            &::placeholder{
                @apply opacity-40
            }
        }
    }

    &-results {
        @apply
            flex 
            flex-row 
            absolute 
            left-0 
            top-0
            w-full 
            min-h-fit 
            shadow-xl
            transition-all
            ease-in-out
            
            /* 
             * I don't like these hardset values but they match the input field perfectly 
             **/
            pt-[80px]
            pb-2
            rounded-[40px];
    }

    &-resultsHits {
        @apply
            relative 
            flex 
            flex-col
            bg-white
            rounded-b-[40px]
            transition-all
            ease-in-out;

        .Search-result {
            @apply
                px-8;
        }
    }
}

.HelpItem{
    a{
        @apply
            p-2
            text-black
            whitespace-nowrap
            text-ellipsis
            overflow-hidden
            block
            border
            border-black
            transition-all;
    }

    &:nth-child(odd) a{
        @apply rounded-tr-sm rounded-bl-sm;
    }
    &:nth-child(even) a{
        @apply rounded-br-sm rounded-tl-sm;
    }

    a:hover{
        @apply 
            shadow-black 
            shadow-box-xs 
            -translate-x-sm 
            -translate-y-sm;
    }  
    
}

.ArticleBody{
    @apply w-10/16 mx-auto my-8;

    @screen lg{
        @apply w-3/4 
    }
    @screen md{
        @apply w-full
    }

    &-imageText{
        @apply 
            flex
            justify-between
            items-center
            my-15;
        
        img{
            @apply 
                w-7/16
                rounded-tr-lg
                rounded-bl-lg
                object-cover
                self-stretch
                ;
        }
        figcaption{
            @apply w-6/16 py-5;
        }

        &:nth-of-type(even){
            @apply flex-row-reverse;

            img{
                @apply 
                    w-7/16
                    rounded-tr-none
                    rounded-bl-none
                    rounded-tl-lg
                    rounded-br-lg;
            }
        }

        @screen lg{
            figcaption{
                @apply w-1/2;
            }
        }

        @screen md{
            &:nth-of-type(even),
            &{
                @apply flex-col my-5;

                figcaption, img{
                    @apply w-full;
                }
                img{
                    aspect-ratio: 1.77;
                }
            }
            

           

            
        }

    }

    .plyr{
        @apply my-5
    }

    
    
}

