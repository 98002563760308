$dist: '/assets/dist/images';
$rem-baseline: 18px;
$max-width: 1480px;


// Colors
$black:#081F2C;
$white:#ffffff;

$blue: #07adc7;
$blue-dark: #3f8692;

$green: #78bd1f;
$green-medium: #6eb516;
$green-dark: #619125;

$gray: #dce0e1;
$gray-light: #f5f7fa;
$gray-lighter: #D3D9E2;
$gray-line: #dce0e1;
$gray-medium: #979cb2;
$gray-dark: #5f6486;

$red: #D82323;



//Screens
$xl: 	    1440px;
$lg-plus: 	1280px;
$lg: 	    1024px;
$md-plus: 	980px;
$md: 	    768px;
$sm: 	    480px;


//As CSS Variables
:root {
    --xl: 	    1440px;
    --lg: 	    1024px;
    --md-plus: 	980px;
    --md: 	    768px;
    --sm: 	    480px;

    --black:#{$black};
    --white:#{$white};

    --blue: #{$blue};
    --blue-dark: #{$blue-dark};
    --green: #{$green};
    --green-medium: #{$green-medium};
    --green-dark: #{$green-dark};
    --gray: #{$gray};
    --gray-light: #{$gray-light};
    --gray-line: #{$gray-line};
    --gray-medium: #{$gray-medium};
    --gray-dark: #{$gray-dark};

}

//Spacing
$spacing-0: 0rem;
$spacing-1: 0.444444rem; //8px
$spacing-2: 0.88888rem; //16px
$spacing-3: 1.333333rem; //24px
$spacing-4: 1.777777rem; //32px
$spacing-5: 2.222222rem; //40px
$spacing-6: 2.666666rem; //48px
$spacing-7: 3.111111rem; //56px
$spacing-8: 3.555555rem; //64px
$spacing-9: 4rem; //72px
$spacing-10: 4.44444rem; //80px
$spacing-15: 6.6666rem; //120px
$spacing-20: 8.8888rem; //160px
$spacing-30: 13.3333rem; //240px

//easing functions
$linear         : cubic-bezier(0.250, 0.250, 0.750, 0.750);
$ease           : cubic-bezier(0.250, 0.100, 0.250, 1.000);
$ease-in        : cubic-bezier(0.420, 0.000, 1.000, 1.000);
$ease-out       : cubic-bezier(0.000, 0.000, 0.580, 1.000);
$ease-in-out    : cubic-bezier(0.420, 0.000, 0.580, 1.000);

$easeInQuad     : cubic-bezier(0.550, 0.085, 0.680, 0.530);
$easeInCubic    : cubic-bezier(0.550, 0.055, 0.675, 0.190);
$easeInQuart    : cubic-bezier(0.895, 0.030, 0.685, 0.220);
$easeInQuint    : cubic-bezier(0.755, 0.050, 0.855, 0.060);
$easeInSine     : cubic-bezier(0.470, 0.000, 0.745, 0.715);
$easeInExpo     : cubic-bezier(0.950, 0.050, 0.795, 0.035);
$easeInCirc     : cubic-bezier(0.600, 0.040, 0.980, 0.335);
$easeInBack     : cubic-bezier(0.600, -0.280, 0.735, 0.045);

$easeOutQuad    : cubic-bezier(0.250, 0.460, 0.450, 0.940);
$easeOutCubic   : cubic-bezier(0.215, 0.610, 0.355, 1.000);
$easeOutQuart   : cubic-bezier(0.165, 0.840, 0.440, 1.000);
$easeOutQuint   : cubic-bezier(0.230, 1.000, 0.320, 1.000);
$easeOutSine    : cubic-bezier(0.390, 0.575, 0.565, 1.000);
$easeOutExpo    : cubic-bezier(0.190, 1.000, 0.220, 1.000);
$easeOutCirc    : cubic-bezier(0.075, 0.820, 0.165, 1.000);
$easeOutBack    : cubic-bezier(0.175, 0.885, 0.320, 1.275);

$easeInOutQuad  : cubic-bezier(0.455, 0.030, 0.515, 0.955);
$easeInOutCubic : cubic-bezier(0.645, 0.045, 0.355, 1.000);
$easeInOutQuart : cubic-bezier(0.770, 0.000, 0.175, 1.000);
$easeInOutQuint : cubic-bezier(0.860, 0.000, 0.070, 1.000);
$easeInOutSine  : cubic-bezier(0.445, 0.050, 0.550, 0.950);
$easeInOutExpo  : cubic-bezier(1.000, 0.000, 0.000, 1.000);
$easeInOutCirc  : cubic-bezier(0.785, 0.135, 0.150, 0.860);
$easeInOutBack  : cubic-bezier(0.680, -0.550, 0.265, 1.550);