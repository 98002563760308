.ProductBox{
    @apply
        rounded-lg
        rounded-tr-none
        rounded-bl-none
        bg-gray-light
        flex
        flex-col
        gap-4
        items-center
        py-4
        px-3
        transition-all
        border
        border-transparent
        w-full
        text-gray-dark;

    &:hover{
        @apply
            shadow-box-small
            shadow-black
            -translate-x-1
            -translate-y-1
            border
            border-black;
    }
    
    &-body{
        @apply 
            pt-4 
            border-t;
        border-color: lighten($color: $gray-medium, $amount: 21%)

    }
    ul{
        @apply
            flex
            flex-col
            gap-2
    }

    li{
        @apply
            flex
            gap-3
            text-black
            text-16
            leading-relaxed;
    }

    li::before{
        content: url("#{$dist}/checkmark.svg");
        @apply block leading-none;
        transform: translateY(1px);
    }
}


.Rate{
    

    &-number{
        @apply
            text-87
            text-green-medium
            relative
            block
            flex
            flex-wrap
            justify-center
            mb-1;
        gap: 6px;

        span{
            @apply inline-block align-baseline;
            line-height: remto(72px);
        }

    //visual help with centering
    .Rate-unit:first-child{
        @apply -ml-3;
    }

    }

    sup,sub{
        @apply
            text-green-medium
            text-32
            inline-block
            static 
            leading-none;        
    }

    sub{
        @apply self-end;
    }

    sup{
        @apply self-start;

        small{
            @apply block text-18 font-bold;
        }
    }

    &-label{
        @apply 
            text-center
            font-normal
            text-gray-dark
            basis-full;
    }
}