.ProductHero{
    --ProductHeroHeight: calc(100vh - (var(--navHeight)*2));
    height: var(--ProductHeroHeight);
    min-height: 800px;

    &-image{
        @apply 
            absolute
            top-0
            left-0
            w-1/2
            h-full;
        max-height: var(--ProductHeroHeight);
        min-height: 800px;

        img{
            @apply
                w-full
                h-full
                object-cover
                rounded-br-2xl;
        }
    }

    &-intro{
        @apply
            w-7/16 
            pt-10
            pb-5;
    }

    @screen md{
        @apply min-h-0 h-auto;

        &-image{
            @apply relative w-full h-auto min-h-0;

            img{ @apply rounded-tl-2xl }
        }

        &-intro{
            @apply py-10 w-full;
        }
    }
}

.Donkey{

    &-pin{
        @apply
            absolute 
            bg-white 
            border 
            border-black 
            rounded-sm 
            p-1 
            text-black 
            font-bold
            text-16
            whitespace-nowrap
            flex
            items-center
            -translate-y-1/2;
        
        &::before{
            content: '';
            @apply
                mr-sm
                inline-block
                w-3
                h-3
                bg-no-repeat;
            background-image: url("#{$dist}/checkmark--green.svg");
        }

        /** @screen lg{  @apply hidden; } **/
    }
}

.Subnav{
    @apply
        border-b
        border-gray-line
        pb-5
        relative;

    ul{
        @apply 
            flex
            gap-20;
    }

    a{
        @apply
            text-black;
    }

    &::before{
        content: '';
        @apply
            bottom-4
            absolute
            border-b-2
            border-blue;
        left: var(--pos);
        width: var(--width);
        transition: all .3s $easeInOutQuad;
    }

    @screen md{
        ul{ @apply gap-5 justify-between; }

        a{ @apply text-18; }
    }

    @screen sm{
        ul{ @apply hidden }
    }
}



.ProductOverview{
    @apply 
        mt-9 
        flex 
        flex-wrap 
        justify-between
        gap-y-5
        mb-20;

    &-keyStats{
        @apply
            w-6/16
            bg-gray-light
            rounded-tr-xl
            rounded-bl-xl
            px-6
            py-6
            flex
            flex-col
            gap-8;

        h3{
            @apply text-[62px]
        }

        .Rate {
            &-separator {
                @apply
                    w-[1px]
                    bg-gray-lighter
                    origin-center
                    rotate-[30deg];

                content: ' ';
            }

            &-number {
                .Rate-unit {
                    &:first-child {
                        @apply 
                            ml-0
                            relative
                            top-[22px];
                    }
                }
            }
        }
    }

    &-keyFeatures{
        @apply 
            w-9/16 
            grid 
            grid-cols-2
            gap-15
            pt-3;
    }

    img{
        @apply h-9 mb-3;
    }

    @screen lg{
        &-keyFeatures{
            @apply gap-3;
        }

        &-keyStats {
            @apply px-4;
        }
    }

    @screen md{
        &-keyStats{
            @apply 
                w-full 
                grid 
                grid-cols-2
                rounded-tl-xl
                rounded-br-xl
                rounded-bl-none
                rounded-tr-none;
        }
        &-keyFeatures{
            @apply w-full;
        }
    }

    @screen sm{
        &-keyStats{ @apply grid-cols-1; }
        &-keyFeatures{
            @apply grid-cols-1;
        }
    }
}

.HowSteps{
    @apply
        w-6/16
        flex
        flex-col
        gap-8;
    counter-set: steps;
    

    &-step{
        @apply
            flex
            flex-col
            gap-2
            shadow-black
            shadow-box-small;

        p{ @apply mt-0 }

        &::before{
            counter-increment: steps;
            content: counter(steps);
            @apply
                text-white
                bg-green
                rounded-sm
                block
                text-center
                w-3
                h-3
                font-bold
                text-16;
        }
    }

    @screen md{
        @apply w-full max-w-2xl;
    }
}

.ComparisonTable{

    &-table{
        @apply grid gap-x-3;
        grid-template-columns:  repeat(var(--cols), minmax(0, 1fr));
    }


    &-header {
        @apply
            bg-gray-light
            flex
            flex-col
            justify-between
            items-center
            p-2
            pt-7
            rounded-tr-lg
            mb-3;

        &--current{
            @apply
                bg-white
                rounded-none
                rounded-tr-lg
                shadow-box-small
                shadow-black
                mb-4;
        }
    }

    &-cell{
        @apply 
            p-2
            relative;

        &::after{
            content: '';
            @apply
                border-b-2
                border-gray-line
                block
                absolute
                -left-3
                -right-3
                bottom-0
                ;
        }
    }

    &-sectionHeader{
        grid-column:1 / span var(--cols) ;
        @apply
            border-b-2
            border-black
            p-2
            text-center;
    }

    //Responsive
    @screen md{

        &-table{
            @apply gap-0 border-collapse relative;
            // column-gap: 2px;
            // row-gap: 2px;
            column-gap:-1px;
            grid-template-columns:  repeat(2, minmax(0, 1fr));

            
        }

        &-header{
            @apply mx-2;
        }

        &-sectionHeader{
            grid-column:1 / span 2 ;
            @apply bg-white;
        }

        &-cell::after{
            @apply left-0 right-0 hidden;
        }

        &-cell{ 
           @apply bg-gray-light;
        }

        &-cell--rowHeader{
            @apply 
                col-span-2 
                text-center 
                font-bold 
                bg-white
                text-black
                border-b
                border-black
        }
        &-cell--current{
            @apply  bg-white
        }
        
    }

    @screen sm{
        &-header{
            @apply mx-1 px-1;

            p{ @apply hidden; }
            h4{ @apply text-18 }
            .btn{ @apply whitespace-nowrap; 
            }
            &--current{
                @apply shadow-none;
            }
        }
    }

}