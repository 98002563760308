$total-height: calc(100vh - 105px - 95px);
@import '~empty-state/skeleton';

.LocationFinder {
    @apply grid relative z-0 overflow-hidden;
    grid-template-rows: 95px $total-height;
    grid-template-columns: minmax(300px, 20%) 1fr;
    

    &-mapContainer {
        @apply row-start-1 row-end-3 col-start-2 col-end-2 relative overflow-hidden z-0;
        -ms-grid-row: 1;
        -ms-grid-row-span: 3;
        -ms-grid-column: 2;
        transition: transform .4s $easeOutQuad;
        border: 2px solid #C2D9DD;

        &.is-pushed {
            transform: translateX(30%);
            transition: transform 1s $easeOutCirc .1s;
        }
    }

    &-searchContainer {
        @apply 
            bg-white 
            px-4 
            py-3 
            relative 
            row-start-1 
            row-end-1 
            col-start-1 
            col-end-1 
            z-50 
            border-b 
            border-[#EBEFF5];
        -ms-grid-row: 1;
        -ms-grid-row-span: 1;
        -ms-grid-column: 1;
        //box-shadow: 2.76726px 0px 2.21381px rgba(55, 108, 164, 0.0253031), 6.6501px 0px 5.32008px rgba(55, 108, 164, 0.0363503), 12.5216px 0px 10.0172px rgba(55, 108, 164, 0.045), 22.3363px 0px 17.869px rgba(55, 108, 164, 0.0536497), 41.7776px 0px 33.4221px rgba(55, 108, 164, 0.0646969), 100px 0px 80px rgba(55, 108, 164, 0.09);
    }
    &-sidebar {
        @apply 
            relative 
            z-10 
            bg-white;
        //box-shadow: 2.76726px 0px 2.21381px rgba(55, 108, 164, 0.0253031), 6.6501px 0px 5.32008px rgba(55, 108, 164, 0.0363503), 12.5216px 0px 10.0172px rgba(55, 108, 164, 0.045), 22.3363px 0px 17.869px rgba(55, 108, 164, 0.0536497), 41.7776px 0px 33.4221px rgba(55, 108, 164, 0.0646969), 100px 0px 80px rgba(55, 108, 164, 0.09);
        overflow-y: auto;
       
        grid-column: 1 / 1;
        grid-row: 2 / 3;
       
        -ms-grid-row: 2;
        -ms-grid-row-span: 3;
        -ms-grid-column: 1;
        max-height: calc(100vh - 140px - 95px);
    }

    &-sidebarHead {
        display: none;
    }

    &-list {}

    &-listDetails {
        display: none;
    }

    &-noResultsMessage {
        @apply p-2 text-center;
        font-size: remto(14px);

    }

    &-listLink {
        @apply 
            block 
            no-underline 
            pr-4
            pl-7
            relative
            pt-2;

        p {
            @apply 
            m-0 
            text-gray-dark
            capitalize;
        }

        &:hover {
            @apply bg-gray-light
        }

        &::before {
            @apply 
                inline-block 
                rounded-full 
                h-1 
                w-1 
                bg-blue-dark 
                absolute
                left-4;
            content: '';
            transform: translateY(calc(100% + 1px));
        }

        &--bank::before{
            @apply bg-green-medium;
        }

        &::after{
            content: '';
            @apply
                block
                mt-2
                border-b
                border-[#EBEFF5];
        }

        

    }

    &-listTitle {
        @apply text-black mb-sm;

    }


    &-errorMessage {
        @include position(absolute, $spacing-2 30% null 30%);
        z-index: 5;
        color: $red;
        background: white;
        padding: $spacing-1 $spacing-2*3;
        border-radius: 5px;
        text-align: center;
        border-left: 3px solid $red;

    }

    &-loadingSpinner {
        @apply 
            z-50 
            grid 
            items-center 
            content-center 
            justify-center 
            rounded-full
            absolute
            overflow-hidden;
        top: 35px;
        left: 44px;
        @include size(25px, 25px);

        @screen md{
            top: 31px;
            left: 39px;
        }
    }
}

.Autocomplete {

    &-input {
        @apply 
            bg-gray-light 
            text-black
            border-none
            rounded-full 
            relative 
            pr-1 
            min-w-0 
            w-full 
            h-6 
            z-50
            pl-7;
        background-image: url($dist + '/icn-search--gray.svg');
        background-repeat: no-repeat;
        background-position: $spacing-2 center;
        transition: all .6s $easeOutExpo;

        &:focus,
        &:active {
            @apply outline-none;
            background-color: darken($gray-light, 3%)
        }

        &::placeholder{
            @apply text-gray-medium;
        }

        &.is-loading{
            background-image: none;
        }
    }

    &-results {
        @apply 
            w-0 
            min-w-0 
            bg-white 
            overflow-hidden 
            opacity-0 
            rounded-lg
            rounded-tr-none
            rounded-bl-none;
        @include position(absolute, 0 0 null 0);
        z-index: 1;
        transition: width .6s $easeOutExpo;

    }

    &-resultItem {
        @apply 
            text-gray-dark 
            py-2 
            pl-6
            pr-4 
            no-underline 
            block 
            truncate 
            leading-none
            text-16;
        transition: all .2s $easeInOutQuad;

        &:hover {
            @apply bg-gray-light;
        }

        &::before {
            @apply inline-block mr-2;
            content: url($dist+'/icn-mapPin--small.svg');
            vertical-align: -2px;
            width: 24px;
        }

        &--nearme {
            &::before {
                content: url($dist+'/icn-nearme.svg');
                vertical-align: -4px;
            }
        }
    }

    &.is-open {
        

        .Autocomplete-input {
            min-width: $spacing-10 * 5;
        }

        .Autocomplete-results {
            transition: all .6s $easeOutExpo;
            min-width: ($spacing-10 * 5)+ $spacing-7;
            padding: $spacing-2*5 0 $spacing-2;
            opacity: 1;
            border-right: 8px solid $black;
            border-bottom: 8px solid $black;
            // box-shadow: 8px 8px 0px $black, 0px 8px $black, 8px 0px $black;
            
        }

    }
}


/**
 * ******************************************
 * Location Finder Mobile Layouts
 * ******************************************
*/

@screen lg {
    .LocationFinder {
        display: flex;
        flex-direction: column;
        height: calc(100vh - 98px - #{$spacing-8});
        z-index: 25;

        &-searchContainer {
            border-bottom: none;
        }

        &-sidebar {
            @apply 
                order-3 
                h-8 
                overflow-hidden 
                w-full 
                fixed bottom-0
                border-t-2
                border-gray-line;
            z-index: 49;
            transition: height .5s ease;

            &.is-visible {
                height: calc(100% - var(--navHeight));
                max-height: 100%;
                overflow-x: auto;
                overflow-y: scroll;
                //padding-top: $spacing-2*3;

                .LocationFinder-sidebarHead {
                    @apply
                        top-0
                        translate-y-0
                        bg-no-repeat
                        bg-white;
                    background-position: $spacing-2 $spacing-2;
                    background-image: url($dist + '/icn_close.svg');
                }
            }
        }

        &-sidebarHead {
            @apply grid items-center cursor-pointer py-2 text-16 font-semibold h-8;
            text-indent: $spacing-8;
            @apply bg-white;
            // background: white url($dist + 'images/list.svg') $spacing-2 50% no-repeat;
            //@include position(fixed, 100% 0 null 0);
            transform: translateY(0%);
            transition: all .5s ease;
        }

        &-mapContainer {
            @apply flex-1;
            height: calc(100vh - 105px);
        }

        &-listDetails {
            ul {
                display: flex;
                flex-flow: wrap;

                li {
                    flex-basis: 50%;
                }
            }
        }

        .is-active .LocationFinder-listDetails {
            display: block;
        }
    }


    .Autocomplete {
        border-top: 2px solid #C2D9DD;
        z-index: 3;

        &-results {
            width: unset;
            min-width: unset;
        }
    }
}

.branchFilter {
    @apply 
        bg-[#16384B]
        cursor-pointer
        rounded-md
        absolute
        top-2
        right-6;
    
    // @include position(absolute, $spacing-2 2.5% null null);
    z-index: 1;
    border-radius: $spacing-4;

    &-label {
        @apply
            font-bold 
            text-white 
            cursor-pointer 
            block 
            bg-black
            px-4
            py-2;
        border-radius: $spacing-4;
        line-height: 1;

        &::after {
            @apply inline-block ml-2;
            content: url($dist + '/arrow-down--white.svg');
        }
    }

    &-options {
        @apply 
            h-0 
            overflow-hidden 
            px-3;
        transition: all .3s ease;

        &.is-open {
            @apply py-2;
            height: $spacing-7 * 3;
        }
    }

    &-option {
        @apply flex flex-row-reverse items-center mb-3 cursor-pointer;

        &>label {
            @apply 
                text-white 
                cursor-pointer 
                font-bold 
                text-16 
                flex-1 
                ml-2;

        }
    }

    &-toggle {
        @apply cursor-pointer;

        input[type=checkbox] {
            @apply hidden h-0 w-0 invisible;
        }

        input+label {
            @apply 
            cursor-pointer 
            w-6 
            h-sm 
            relative 
            rounded-full 
            block 
            bg-[#778995];
            text-indent: -9999px;
        }

        &:hover input+label {
            @apply bg-opacity-75;
        }

        input+label:after {
            @apply 
                absolute 
                bg-[#778995]
                rounded-full;
            content: '';
            @include size(20px);
            top: -1 * $spacing-1;
            left: 0px;
            transition: 0.3s;
            box-shadow: 1px 1px 4px rgba(69, 90, 139, 0.5);
        }

        input:checked+label {
            @apply bg-white
        }

        input:checked+label:after {
            @apply bg-white;
            left: calc(100%);
            transform: translateX(-100%);
        }

        input+label:active:after {
            width: $spacing-3 + $spacing-1;
        }
    }
}

/*************************************
* Info Window
*************************************/
.gm-style-iw.gm-style-iw-c {
    @apply p-3;
    overflow: hidden;
    width: 412px;
    max-width: 412px !important;

    button {
        opacity: 0
    }
}

.gm-style-iw-d {
    max-width: 412px !important;
}

.InfoWindow {
    @apply pb-3 bg-blue-dark;
    overflow: hidden;
    width: 380px;
    color: white;

    &-details {
        @apply pr-3 w-1/2;
        border-right: 1px solid #6d99a9;
    }

    &-name {
        @apply text-white text-16;
    }

    &-directionsLink {
        @apply text-16 font-sans text-white font-bold underline;
    }

    &-address {
        @apply text-16 font-sans;
        margin-bottom: $spacing-3;
    }

    &-features {
        min-width: 160px;
        margin-left: $spacing-3;
    }

    &-feature {
        margin-bottom: $spacing-1 * 1.5;
        white-space: nowrap;
        font-size: remto(16px);

        strong {
            vertical-align: middle;
            white-space: nowrap;
            color: white;
        }

        small {
            white-space: nowrap;
        }

        img {
            vertical-align: -3px;
            margin-right: $spacing-1;
            display: inline-block;
        }
    }
}

/*************************************
* Branch Pane
*************************************/
.BranchPane {
    @apply 
        absolute 
        top-0 
        left-0 
        w-8/12 
        bg-white 
        pr-3 
        pl-8;
    border-top: 2px solid #C2D9DD;
    border-right: 2px solid #C2D9DD;
    z-index: 99;
    height: calc(100vh - var(--navHeight));
    overflow-y: auto;
    transform: translateX(-100%);
    transition: all .6s $easeOutQuad;

    @screen lg {
        @apply w-full fixed h-screen top-0;
        transform: translateY(100%);
    }

    &.is-open {
        @apply w-8/12 h-full;
        transform: translateX(0%);

        @screen lg {
            @apply w-full;
            //transform: translate(0%);
        }
    }

    .GoBack {
        @apply -mr-3 -ml-8 pl-8 mb-3 pt-3;
    }

    &-body {

    }

    &-photo {
        img {
            @apply rounded-sm;
            box-shadow: 8px 8px 0px $blue;
        }
    }

    &-phones {
        @apply grid mt-5;
        grid-template-columns: 1fr max-content;

        dt,
        dd {
            @apply py-2 border-b border-blue leading-none
        }

        dt {
            @extend h5;
            @apply leading-none;
        }

        dd {
            @apply text-right
        }
    }

    &-features {}

    &-feature {
        @apply mb-3 leading-none;

        &::before {
            @apply mr-2;
            content: url($dist + '/cross--gray.svg');
            vertical-align: -5px;
        }

        &--yes::before {
            content: url($dist + '/checkmark--green.svg')
        }
    }

    &-hours {
        @apply bg-blue bg-opacity-50;

        table {
            @apply w-full
        }

        th,
        td {
            @apply pb-2 text-16 px-3;
        }

        th {
            @apply font-semibold pt-2 text-left;
        }
    }


    //Empty States
    &-photo:empty {
        height: $spacing-10 * 4;
        $shape-photo: rect($blue, 100%, 400px, 0, 0);
        @include skeleton($shape-photo);
        animation: pulse 2s linear infinite;
    }

    &-info:empty {
        height: $spacing-10 * 4;
        $shapes: join(lines(3, $blue, 80%, 24px, 16px, 0px, 0px, 30%),
            lines(2, $blue, 100%, 23px, 17px, 0, 250px));
        @include skeleton($shapes...);
        animation: pulse 2s linear infinite;
    }

    &-features:empty {
        height: $spacing-10 * 3;
        $shapes: lines(5, $blue, 60%, 23px, 30px, 32px, 0px, 60%);
        $shapes: append($shapes, circle($blue, 28px, 0, 0));
        $shapes: append($shapes, circle($blue, 28px, 0, 50px));
        $shapes: append($shapes, circle($blue, 28px, 0, 105px));
        $shapes: append($shapes, circle($blue, 28px, 0, 157px));
        $shapes: append($shapes, circle($blue, 28px, 0, 210px));
        @include skeleton($shapes...);
        animation: pulse 2s linear infinite;
    }

    &.is-loading .BranchPane-hours {
        animation: pulse 2s linear infinite;
    }
}

@keyframes pulse {
    from {
        opacity: 1;
    }

    50% {
        opacity: .5;
    }

    to {
        opacity: 1;
    }
}