@import 'searchModal';
@import 'loginModal';

:root{
    --navHeight: 135px;
}

.Nav{
    @apply
        flex
        items-center
        justify-center
        z-[100]
        relative;
    height: var(--navHeight);

    @screen sm {
        @apply 
            sticky
            top-0;
    }

    &>.container>*{
        @apply relative z-20;
    }

    .logo{
        @apply -translate-y-1;
    }

    //main nav items with dropdowns
    &-main{
        @apply
            flex
            gap-8
            relative;
        
        a{
            @apply
                font-bold
                text-black;
        }

        &::before{
            content: '';
            @apply
                -bottom-sm
                absolute
                border-b-2
                border-blue;
            left: var(--pos);
            width: var(--width);
            transition: all .3s $easeInOutQuad;
        }

        @screen lg+ {
            @apply gap-5;
        }
    }

    &-utility{
        @apply 
            flex
            gap-3
            items-center;


        &>a:not(.btn),
        &>button{
            @apply 
                uppercase
                text-12
                font-bold
                tracking-wider
                text-black
                transition-none
                gap-1;
        }
       
        @screen lg+ {
           
        }
    }
    
    &-searchBtn{
        background-image: url("#{$dist}/icn-search.svg");
        width: 17px;
        height: 17px;
    }

    &-locationBtn,
    &-loginBtn{
        @apply flex items-center gap-1;
    }
    
    &-searchBtn,
    &-loginBtn{
        @apply 
            rounded-none
            bg-transparent
            p-0
            bg-no-repeat
            uppercase;
    }

    &-loginBtn::before{
        content: url("#{$dist}/icn-lock.svg");
    }

    &-hamburger{
        @apply hidden;
    }

    &.is-mobile {
        @apply
            w-full
            max-w-full;
    }
}

.logo{
    @apply
        block
        bg-no-repeat;
    
    height: 54px;
    aspect-ratio: calc( 192/54 );
    background: url("#{$dist}/logo.svg") center/100% no-repeat;
    text-indent: -9999px;
    
    //2023 50 year anniversary logo
    &--50{
        background: url("#{$dist}/logo-50.svg") left/100% no-repeat;
        background-size: contain;
        height: 75px;
        aspect-ratio: calc( 192/84 );
    }

    @screen lg+ {
        width: 130px;
    }
}





/*************************************
* Hamburger Memu
*************************************/

@screen lg{
    .Nav{
        &-hamburger{
            @apply block;
        }
        &-main{ @apply hidden; }
        &-utility>a.btn{
            @apply hidden;
        }
    }
}

.Nav.is-mobile.is-open{
    @apply min-h-screen overflow-auto block;
    padding-top: 30px;
}

.Nav-hamburger{
    @apply
        relative
        z-20
}
.BurgerNav{
    @apply
        min-h-screen
        h-full
        w-full
        absolute
        top-0
        left-0
        translate-x-full
        bg-gray-light
        transition-transform
        ease-out
        z-10
        bg-white;

        /* min-h-screen
        h-full
        
        max-height: 99999px; */

    margin-top: var(--navHeight);
    max-height: 99999px; 
    max-width: 100vw;

    &.is-open{
        @apply translate-x-0;
    }

    @screen sm {
        &-pane {
            @apply
                overflow-y-scroll;
        }
    }

    &-list{
        @apply
            border-t
            border-gray-line;

        a{
            @apply
                grid
                p-2
                border-b
                border-gray-line
                text-24
                text-black
                font-bold
                gap-1
                items-center;
            grid-template-columns: $spacing-4 1fr $spacing-1;

            &::after{
                content: url("#{$dist}/arrow-right.svg")
            }

            span{
                grid-column-start: 2;
            }

            &.BurgerNav-cta{
                @apply
                    bg-green
                    text-white;

                &::after{
                    content: url("#{$dist}/arrow-right--inverted.svg")
                }
            }
        }

        li:last-of-type>a{@apply border-b-0}
    }

    &-back{
        @apply 
            flex
            gap-1
            items-center
            ml-7
            text-blue-light
            py-2;

        &::before{
            content: inline-svg('<svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M6.67309 0.134369L7.82684 1.28812L2.48059 6.63437L7.82684 11.9806L6.67309 13.1344L0.173093 6.63437L6.67309 0.134369Z" fill="#{$blue-dark}"/> </svg>')
        }
    }
}



/*************************************
* Dropdowns
*************************************/
$dropdownTransition: .4s $easeOutQuad;
.Dropdown{
    @apply
        absolute
        z-10
        top-0
        left-0
        right-0
        bg-white
        rounded-bl-md
        rounded-br-md
        opacity-0;
    padding-top: var(--navHeight);
    transform: perspective(1000px) rotateX(-25deg);
    visibility: hidden;
    transform-origin: top center;
    transition: all $dropdownTransition;

    &.is-open{
        transform: perspective(1000px) rotateX(0deg);
        box-shadow: 0px 8px 0px $gray-light;
        opacity: 1;
        visibility: visible;

        .Dropdown-container{
            @apply opacity-100 bg-white;
        }
    }

    &-container{
        @apply
            flex 
            justify-between
            gap-3 
            items-start
            pb-8
            opacity-0
            bg-green;
        transition: all .5s $easeOutQuad .0s;
    }

    
}




.DropTabs{
    @apply
        flex
        w-10/16
        shrink-0;

    &-list{
        @apply
            flex
            flex-col
            shrink-0
            gap-2
            pr-4
            border-r
            border-gray-line;
    }

    &-tab{
        @apply
            bg-white
            rounded-sm
            p-2
            gap-9
            text-gray-dark
            flex
            justify-between
            text-left
            w-full
            items-center;

        &::after{
            content: url("#{$dist}/arrow-right--green.svg");
            line-height: 0;
        }

        &:hover{
            @apply
                bg-gray-light
                translate-y-0
                translate-x-0
                shadow-none;
        }

        &.is-selected{
            @apply
                bg-blue
                text-white;
            
            &::after{
                content: url("#{$dist}/arrow-right--white.svg");
            }
        }
    }
}


.DropNavs{
    @apply
        columns-2
        gap-x-7
        gap-y-4
        space-y-4
        pl-5;
    max-height: 50vh;
    min-height: 390px;

    &-list{
        @apply space-y-2;
        break-inside: avoid;
    }

    li>a{
        @apply text-blue-light;

        &:hover{
            @apply underline;
            color: darken($blue-dark, 5%);
        }
    }
    li>h5>a{
        @apply text-black;
    }
    
}


.DropCTA{
    @apply
        border
        border-gray-line
        rounded-lg
        rounded-tr-none
        p-2
        w-3/16
        flex
        flex-col
        justify-center
        items-center
        text-14
        text-center
        flex-1;
    
    &.is-primary{
        @apply
            shadow-box-small;
    }

    &-visual{
        @apply 
        aspect-square 
        block 
        w-full
        flex
        items-center
        justify-center
        overflow-hidden
        rounded-lg
        rounded-tr-none
        mb-2;
    }

    p{
        @apply
            leading-tight
            text-black;
    }
}

