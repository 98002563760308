html{
    @apply text-base font-sans bg-white;

    @screen md{
        font-size: 16px;
    }

}

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    @apply font-sans text-gray-dark;
}

/*************************************
* Headings
*************************************/

h1, h2, h3,
h4, h5, h6 {
   @apply text-black font-bold;

    &+p{
        @apply mt-1;
    }

    //emoji
    &>img{
        max-height: 1em;
        display: inline;
    }
}

h1{ @apply text-87 }
h2{ @apply text-72 }
h3{ @apply text-40 }
h4{ @apply text-24 }
h5{ @apply text-18 }
h6{ @apply text-16 }

@screen md {

}

@screen sm{
    h1{ 
        hyphens: auto;
        @apply break-words text-72;
         
    }
    h2{ @apply break-words }
    h3{ }
    h4{ }
    h5{ }
    h6{ }
}


/*************************************
* Links
*************************************/

a{
    @apply text-blue-light;

    &:hover,
    &:focus{

    }
}

/*************************************
* Other Type
*************************************/


p {
    @apply  
        tracking-normal
        leading-7;
}

p+p{
    @apply mt-4;
}

h1,h2,h3,h4,h5{
    &+p{
        @apply mt-2;
    }
}

.big, .big>p{ @apply text-24 leading-loose }
.huge{ @apply text-32 leading-relaxed }

sup{
    font-size: 50%;
    top: -1em;
}

p, dl, ol, ul, pre, blockquote {

}
strong{
    @apply text-black;
}

hr{
    @apply
        border-t
        border-gray-line;
}

.prose {
    @apply text-black;

    ol {
        @apply list-decimal;
    }

    ul {
        @apply list-disc;
    }

    ol li,
    ul li {
        @apply pl-2;
    }

    ul, p {
        & + h3 {
            @apply
                mt-3;
        }
    }
}


.emoji-glyph{
    @apply inline-block align-middle;
    width: 1em;
}

