.btn{
    @apply
        text-center
        rounded-full
        px-4
        py-2
        text-white
        text-16
        font-bold
        bg-green
        flex
        items-center
        justify-center
        transition-all
        gap-1
        leading-none
        whitespace-nowrap;


    &:hover,
    &:focus{
        box-shadow: 2px 2px 0px $green-dark;
        transform: translate(-2px, -2px);
    }

    &[disabled]{
        @apply
            bg-opacity-50
            bg-gray-dark
            cursor-not-allowed;
        &:hover,
        &:focus{
            box-shadow: none;
            transform: translate(0,0);
        }
    }

}
.btn--secondary{
    @apply bg-blue;

    &:hover,
    &:focus{
        box-shadow: 2px 2px 0px $blue-dark;
    }
}

.btn--ghost{
    @apply 
        bg-white 
        text-gray-dark
        border
        border-gray-dark;

    &:hover,
    &:focus{
        box-shadow: 2px 2px 0px $gray-dark;
    }
}

.btn--text{
    @apply
        text-blue-light;

    &::after{
        @apply
            inline-block
            ml-2
            translate-y-[1px];
        content: inline-svg('<svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M1.32685 13.5L0.173096 12.3462L5.51935 7L0.173096 1.65375L1.32685 0.5L7.82685 7L1.32685 13.5Z" fill="#{$blue-dark}"/> </svg>')
    }

    &:hover{
        color: darken($blue-dark, $amount: 8);

        &::after{
            content: inline-svg('<svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M1.32685 13.5L0.173096 12.3462L5.51935 7L0.173096 1.65375L1.32685 0.5L7.82685 7L1.32685 13.5Z" fill="#{darken($blue-dark, $amount: 8)}"/> </svg>')
        }
    }
}


/*************************************
* Auto space out button when after a p
*************************************/
p + .btn{
    @apply mt-2;
}