.ProductSlider{
    --gutter: 28px;
    --per-page: 4;

    &-list{
        @apply 
            grid 
            overflow-x-scroll
            py-5
            mt-5;
        cursor: grab;
        // grid-gap: calc(var(--gutter));
        grid-template-columns: 2.5% repeat(var(--total), calc(100% * (4.2 / 16) - var(--gutter) * 2)) 2.5%;
        // grid-template-columns: 2.5% repeat(var(--total), calc(#{100*(4 / 16)}% - var(--gutter) * 2)) 2.5%;
        grid-template-rows: minmax(380px, 1fr);
        -webkit-overflow-scrolling: touch;
        scrollbar-width: none;
        -ms-overflow-style: none;
        scroll-snap-type: x proximity;
        scroll-padding-left: 2.5%;

        &::before,
        &::after {
            content: '';
        }

        &::-webkit-scrollbar{  @apply hidden; }

        &.is-down{
            scroll-snap-type: none;

            li{
                transform: scale(1.02);
            }
            
        }
    }
    
    &-item{
        scroll-snap-align: start;
        @apply relative flex;
        padding-right: var(--gutter);

        &:not(li:last-child)::after{
            content: '';
            @apply 
                absolute;
            top:0;
            bottom:0;
            width:1px;
            right:var(--gutter);
        }

        
    }
    
    &-item:first-child{
        a{
            @apply opacity-100;
        }
    }

    @screen lg+{
        --per-page: 3;
        &-list{
            grid-template-columns: 2.5% repeat(var(--total), calc(100% * (6/ 16) - var(--gutter) * 2)) 2.5%;
        }
    }

    @screen md{
        --per-page: 2;
        &-list{
            grid-template-columns: 2.5% repeat(var(--total), calc(100% * (8 / 16) - var(--gutter) * 2)) 2.5%;
        }
    }
    @media (max-width: 600px){
         --per-page: 1;
        &-list{
            grid-template-columns: 2.5% repeat(var(--total), calc(100% * (13 / 16) - var(--gutter) * 2)) 2.5%;
        }
    }

    @screen sm{
        --per-page: 1;
        &-list{
            grid-template-columns: 2.5% repeat(var(--total), calc(100% * (13 / 16) - var(--gutter) * 2)) 2.5%;
        }
    }

    
}




.Pages,
.swiper-pagination{
    @apply
        flex
        justify-center
        gap-1;

    &-page,
    .swiper-pagination-bullet{
        @apply
            rounded-full
            bg-gray-line
            w-1
            h-1
            transition-all
            duration-500
            cursor-pointer;
        
        &.is-active,
        &.swiper-pagination-bullet-active{
            @apply w-4 bg-blue cursor-default;
        }
    }
}