.HomeHero{
    @apply
        min-h-screen
        relative
        flex
        items-center;
    margin-top: calc(-1 * var(--navHeight));
    padding-top: var(--navHeight);

    &::before{
        content: '';
        @apply
            absolute
            top-0
            right-0
            bottom-0
            bg-cover;
        z-index: -1;
        background-image: var(--bg);
        width: 36%;

        @screen retina{
            background-image: var(--bg2x);
        }

        @screen md{
            @apply w-5/16;
        }

        @media only screen and (max-width: 580px) {
            content: none;
        }
    }

    &-boxes{
    }

    @screen lg{

    }

    @screen md{
        @apply pb-4;

        .AuthBox{
            @apply rounded-bl-none
        }
    }

    
    
}


.AuthBox{
    @apply
        bg-white
        rounded-tr-2xl
        rounded-bl-2xl
        border
        border-black
        p-5;
    box-shadow: 8px 8px 0 $black;

}

.AtmButton{
    @apply
        font-bold
        text-black
        bg-white
        rounded-[15px]
        border
        border-black
        p-3
        inline-flex
        items-center
        gap-5
        ml-6
        whitespace-nowrap
        -translate-y-1/2;
    box-shadow: 4px 4px 0 $blue;
    &::after{
        content: url("#{$dist}/arrow-forward-white.svg");
        @apply
            bg-blue
            rounded-sm
            w-3
            h-3
            flex
            items-center
            justify-center;
    }
}


.WhyPoints{
    @apply
        flex 
        justify-between;
    
    &>div{
        @apply flex flex-col justify-end items-center gap-3;
    }

    @screen lg{
        &>div{
            @apply px-2 w-auto text-center;
        } 
    }

    @screen md{
        @apply flex-wrap gap-6;

        &>div{
            @apply w-7/16;
        } 
    }

    @screen sm{

        &>div{
            @apply w-full;
        } 
    }
}


.Digital{

    &-visuals{
        @apply 
            relative
            px-10;

        &:before{
            content: '';
            @apply
                bg-blue
                rounded-xl
                rounded-bl-none
                rounded-tr-none
                absolute
                z-0
                bottom-0
                right-0
                left-0
                top-9
                text-center;
        }
        img{
            @apply 
                relative 
                z-10
                mx-auto;
        }
    }
}


.HomeCommEvent{
    @apply
        flex
        flex-col
        flex-1
        flex-nowrap
        gap-2
        p-2
        border
        border-black
        rounded-md
        text-gray-dark;
    
    img{
        border-radius: 16px;
    }

}